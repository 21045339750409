import {Image} from "react-bootstrap";
import logo from "../Assests/Images/logo.png";

function Footer() {
	return (
			<div className="footer">
				<div className="logo">
					<Image src={logo} fluid />
				</div>{" "}
				<div dir="ltr">© 2024 Copyright: paighameiqbal.com</div>
			</div>
	);
}

export default Footer;
