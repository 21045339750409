import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "react-bootstrap";
import { api } from "../variable";
import Footer from "../Common/Footer";
import NavigationBar from "../Common/Navbar";

function Audios() {
	const { t } = useTranslation();
	const [audios, setAudios] = useState("");

	useEffect(() => {
		GetAudio();
	}, []);

	const GetAudio = () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch(api + "listofaudios", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setAudios(result.data);
			})
			.catch((error) => console.log("error", error));
	};

	return (
		<div>
			<NavigationBar />
			<div className="audio-details">

				<div className="filter">
					<div className="audio-type">
						<Tabs defaultActiveKey="all" className="mb-3">
							<Tab eventKey="all" title={t("all")}></Tab>
							<Tab eventKey="explanations" title={t("explanations")}></Tab>
							<Tab eventKey="root" title={t("root words")}></Tab>
							<Tab eventKey="music" title={t("music")}></Tab>
						</Tabs>
					</div>
				</div>

				<div className="audios">
					{audios
						? audios.length > 0
							? audios.map((n, index) => (
									<div key={index} className="audio">
										<audio controls>
											<source src={n.youtubeurl} type="audio/mp3" />
										</audio>
									</div>
							  ))
							: ""
						: ""}
				</div>
				
			</div>
			<Footer />
		</div>
	);
}
export default Audios;
