import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Modal, Alert, Col, Row } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { api } from "../variable";

function SignUp({ show, handleClose }) {
	const { t } = useTranslation();
	const [userName, setUserName] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [userPassword, setUserPassword] = useState("");
	const [userConfirmPassword, setUserConfirmPassword] = useState("");
	const [userPhone, setUserPhone] = useState("");
	const [results, setResults] = useState("");
	const [result, setResult] = useState(false);
	const [error, setError] = useState(false);

	const SignUp = (e) => {
		e.preventDefault();
		var formdata = new FormData();
		formdata.append("name", userName);
		formdata.append("password", userPassword);
		formdata.append("password_confirmation", userConfirmPassword);
		formdata.append("email", userEmail);
		formdata.append("phone_number", userPhone);

		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "adduser", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status === true) {
					setUserName("");
					setUserEmail("");
					setUserPassword("");
					setUserConfirmPassword("");
					setUserPhone("");
					setResult(true);
					setResults("آپ کا اکاؤنٹ کامیابی سے بن گیا ہے لاگ ان کیجیے۔");
					setError(false);
				} else {
					setResult(true);
					setError(true);
					if (result.message === "The phone number has already been taken.") {
						setResults("یہ فون نمبر پہلے سے موجود ہے۔");
					} else if (result.message === "The email has already been taken.") {
						setResults("یہ ای میل پہلے سے موجود ہے۔");
					}
				}
				console.log(result);
			})
			.catch((error) => console.log("error", error));
	};
	return (
		<Modal
			show={show}
			onHide={() => {
				handleClose();
				setUserName("");
				setUserEmail("");
				setUserPassword("");
				setUserConfirmPassword("");
				setUserPhone("");
				setResults("");
			}}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{t("sign")}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={SignUp}>
					<Row>
						<Col md={6}>
							<Form.Group className="form-group mb-3">
								<Form.Control
									type="text"
									placeholder="&nbsp;"
									onInvalid={(e) =>
										e.target.setCustomValidity(t("required field"))
									}
									onInput={(e) => e.target.setCustomValidity("")}
									autoFocus
									required
									value={userName}
									onChange={(e) => setUserName(e.target.value)}
								/>{" "}
								<span className="floating-label">{t("name")}</span>
							</Form.Group>
						</Col>
						<Col md={6}>
							<div className="form-group mb-3">
								<PhoneInput
									defaultCountry="PK"
									placeholder={t("phone")}
									onInvalid={(e) =>
										e.target.setCustomValidity(t("required field"))
									}
									onInput={(e) => e.target.setCustomValidity("")}
									required
									value={userPhone}
									onChange={setUserPhone}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Form.Group className="form-group mb-3">
								<Form.Control
									type="email"
									placeholder="&nbsp;"
									required
									onInvalid={(e) =>
										e.target.setCustomValidity(t("required field"))
									}
									onInput={(e) => e.target.setCustomValidity("")}
									value={userEmail}
									onChange={(e) => setUserEmail(e.target.value)}
								/>{" "}
								<span className="floating-label">{t("email")}</span>
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="form-group mb-3">
								<Form.Control
									type="password"
									placeholder="&nbsp;"
									required
									onInvalid={(e) =>
										e.target.setCustomValidity(t("required field"))
									}
									onInput={(e) => e.target.setCustomValidity("")}
									value={userPassword}
									onChange={(e) => setUserPassword(e.target.value)}
								/>{" "}
								<span className="floating-label">{t("password")}</span>
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="form-group mb-3">
								<Form.Control
									type="password"
									placeholder="&nbsp;"
									required
									onInvalid={(e) =>
										e.target.setCustomValidity(t("required field"))
									}
									onInput={(e) => e.target.setCustomValidity("")}
									value={userConfirmPassword}
									onChange={(e) => setUserConfirmPassword(e.target.value)}
								/>{" "}
								<span className="floating-label">{t("confirm password")}</span>
							</Form.Group>
						</Col>
					</Row>{" "}
					{result && (
						<Alert variant={error ? "danger" : "success"}>{results}</Alert>
					)}
					<Button className="btn btn-modal" type="submit">
						{t("sign")}
					</Button>
				</Form>
			</Modal.Body>
		</Modal>
	);
}

export default SignUp;
