import { useEffect, useState } from "react";
import { Container, Image, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { api } from "../variable";
import { useTranslation } from "react-i18next";
import Footer from "../Common/Footer";
import NavigationBar from "../Common/Navbar";

function Books() {
	const [booksData, setBooksData] = useState([]);
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api + "listofbooks", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setBooksData(result.data);
			})
			.catch((error) => console.log("error", error));
	}, []);

	const handlebook = (id, b_name) => {
		navigate(`/bookdetail/${id}`);
	};

	return (
		<div>
			<NavigationBar />
			<Container>
				<div className="books-filter">
					<div className="book-type">
						<Tabs defaultActiveKey="all" className="mb-3">
							<Tab eventKey="all" title={t("all")}></Tab>
							<Tab eventKey="poetry" title={t("poetry")}></Tab>
							<Tab eventKey="prose" title={t("prose")}></Tab>
						</Tabs>
					</div>

					<div className="book-lang">
						<Tabs defaultActiveKey="urdu" className="mb-3">
							<Tab eventKey="urdu" title={t("urdu")}></Tab>
							<Tab eventKey="persian" title={t("persian")}></Tab>
						</Tabs>
					</div>
				</div>

				<div className="books">
					{booksData
						? booksData.length > 0
							? booksData.map((n, index) => (
									<div
										key={index}
										className="book"
										onClick={() => handlebook(n.id, n.name)}
									>
										<h1>
											{n.name.length > 16
												? `${n.name.substring(0, 16)}...`
												: n.name}
										</h1>
										<h2>{n.name}</h2>
									</div>
							  ))
							: ""
						: ""}
				</div>
			</Container>
			<Footer />
		</div>
	);
}
export default Books;
