import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { api } from "../variable";
import Footer from "../Common/Footer";
import NavigationBar from "../Common/Navbar";

function Videos() {
	const { t } = useTranslation();
	const [videos, setVideos] = useState("");

	useEffect(() => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api + "listofvideos", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setVideos(result.data);
			})
			.catch((error) => console.log("error", error));
	}, []);

	const convertToEmbedUrl = (watchUrl) => {
		const videoIdMatch = watchUrl.match(
			/(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=))([^"&?\/\s]{11})/
		);

		if (videoIdMatch && videoIdMatch[1]) {
			const videoId = videoIdMatch[1];
			return `https://www.youtube.com/embed/${videoId}`;
		} else {
			return null;
		}
	};

	return (
		<div>
			<NavigationBar />
			<div className="video-details">
				<div className="filter">
					<div className="video-type">
						<Tabs defaultActiveKey="all" className="mb-3">
							<Tab eventKey="all" title={t("all")}></Tab>
							<Tab eventKey="explanations" title={t("explanations")}></Tab>
							<Tab eventKey="root" title={t("root words")}></Tab>
							<Tab eventKey="music" title={t("music")}></Tab>
						</Tabs>
					</div>
				</div>
				<div className="videos">
					{videos
						? videos.length > 0
							? videos.map((n, index) => (
									<div key={index} className="video">
										<iframe
											width="356"
											height="200"
											src={convertToEmbedUrl(n.youtubeurl)}
											title={n.video_title}
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
											allowfullscreen
										></iframe>
									</div>
							  ))
							: ""
						: ""}
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default Videos;
