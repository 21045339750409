import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./Pages/Main";
import "./Assests/Styles/styles.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Books from "./Pages/Books";
import BookDetails from "./Pages/BookDetails";
import Videos from "./Pages/Videos";
import Audios from "./Pages/Audios";
import Biography from "./Pages/Biography";
import Kids from "./Pages/Kids";
import Poems from "./Pages/Poems";
import Challenge from "./Pages/Challenge";
import Quiz from "./Pages/Quiz";
import Gallery from "./Pages/Gallery";
import Profile from "./Pages/Profile";
import Search from "./Pages/Search";
import About from "./Pages/About";

function App() {
	document.body.style.fontFamily = "Jameel Noori Nastaleeq";

	return (
		<I18nextProvider i18n={i18n}>
			<div>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Main />} />
						<Route path="/books" element={<Books />} />
						<Route path="/bookdetail/:id/:poemId?" element={<BookDetails />} />
						<Route path="/videos" element={<Videos />} />
						<Route path="/audios" element={<Audios />} />
						<Route path="/biography" element={<Biography />} />
						<Route exact path="/kids" element={<Kids />} />
						<Route path="/kids/poems" element={<Poems />} />
						<Route path="/kids/challenge" element={<Challenge />} />
						<Route path="/kids/quiz" element={<Quiz />} />
						<Route path="/gallery" element={<Gallery />} />
						<Route path="/profile" element={<Profile />} />
						<Route path="/about" element={<About />} />
						<Route exact path="/search" element={<Search />} />
						<Route path="/search/:word" element={<Search />} />
					</Routes>
				</BrowserRouter>
			</div>
		</I18nextProvider>
	);
}

export default App;
