import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { api } from "../variable";
import Footer from "../Common/Footer";
import NavigationBar from "../Common/Navbar";

function Search() {
	const [book, setBook] = useState("");
	const [poem, setPoem] = useState("");
	const [stanza, setStanza] = useState("");
	const [meaning, setMeaning] = useState("");
	const [keyword, setKeyword] = useState("");
	const { word } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		GetSearch();
	}, [word]);

	const GetSearch = () => {
		var myHeaders = new Headers();
		var formdata = new FormData();
		formdata.append("word", word);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "search-by-word", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setBook(result.data.Books);
				setPoem(result.data.Poems);
				setStanza(result.data.Stanza);
				setMeaning(result.data.meaning);
				setKeyword(result.data.keywords);
			})
			.catch((error) => console.log("error", error));
	};

	const handlePoem = (id) => {
		if (poem && poem.length > 0) {
			const selectedPoem = poem.find((poemItem) => {
				return poemItem.id === id;
			});

			if (selectedPoem) {
				console.log(
					"Found poem with id:",
					selectedPoem.id,
					selectedPoem.book_id
				);
				navigate(`/bookdetail/${selectedPoem.book_id}/${selectedPoem.id}`);
			}
		}
	};

	return (
		<div>
			<NavigationBar sticky={true} search={word} />
			<div className="search">
				<Container className="detail">
					<Row>
						<Col className="row">
							<h1>{t("books")}</h1>
							{book
								? book.length > 0
									? book.map((n, index) => (
											<div key={index}>
												<a href={`/bookdetail/${n.id}`}>
													{index + 1 + "." + " " + n.name}
												</a>
											</div>
									  ))
									: ""
								: ""}
						</Col>
						<Col className="row">
							<h1>{t("poems")}</h1>
							{poem
								? poem.length > 0
									? poem.map((n, index) => (
											<div key={index} onClick={() => handlePoem(n.id)}>
												<span>{index + 1 + "." + " " + n.poem_name}</span>
											</div>
									  ))
									: ""
								: ""}
						</Col>
						<Col className="row">
							<h1>{t("stanza")}</h1>
							{stanza
								? stanza.length > 0
									? stanza.map((n, index) => (
											<div className="stanza" key={index}>
												<p
													style={{ whiteSpace: "pre-line" }}
													dangerouslySetInnerHTML={{
														__html: index + 1 + "." + " " + n.full_stanza,
													}}
												></p>
												<br />
											</div>
									  ))
									: ""
								: ""}
						</Col>
						<Col className="row">
							<h1>{t("dictionary")}</h1>
							{meaning
								? meaning.length > 0
									? meaning.map((n, index) => (
											<div key={index}>
												<Row>
													<Col>{index + 1 + "." + " " + n.word}</Col>
													<Col>{n.meaning}</Col>
												</Row>
											</div>
									  ))
									: ""
								: ""}
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />
		</div>
	);
}
export default Search;
