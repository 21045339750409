import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "../src/Assests/Locales/en.json";
import translationUr from "../src/Assests/Locales/ur.json";


const resources = {
	en: { translation: translationEn },
	ur: { translation: translationUr },
};
document.documentElement.dir = "rtl";
i18n.use(initReactI18next).init({
	resources,
	lng: "ur",
	fallbackLng: "ur",
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
