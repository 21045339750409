import { useEffect, useState } from "react";
import {
	Button,
	Col,
	Row,
	Modal,
	InputGroup,
	Form,
	FormControl,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { api } from "../variable";
import Footer from "../Common/Footer";
import NavigationBar from "../Common/Navbar";
import Login from "../Common/Login";

function Quiz() {
	const [q_id, setQid] = useState("");
	const [q_no, setQNo] = useState("");
	const [pdfQNo, setPdfQNo] = useState("");
	const [qSearch, setQSearch] = useState("");
	const [quizName, setQuizName] = useState("");
	const [qSuggest, setQSuggest] = useState("");
	const [quizDetails, setQuizDetails] = useState("");
	const [q_ans, setAns] = useState(null);
	const [activeIndex, setActiveIndex] = useState(null);
	const [qKeywords, setQKeywords] = useState([]);
	const [qlist, setQList] = useState(false);
	const [create, setCreate] = useState(false);
	const [showLogin, setShowLogin] = useState(false);
	const { t } = useTranslation();
	const token = localStorage.getItem("token");

	const handleCloseLogIn = () => setShowLogin(false);
	const handleShowLogIn = () => setShowLogin(true);

	const handleClose = () => {
		setCreate(false);
		setQKeywords([]);
		setQNo("");
		setAns("");
	};
	const handleShow = () => {
		if (!token) {
			handleShowLogIn();
		} else {
			setCreate(true);
		}
	};

	useEffect(() => {
		console.log(qKeywords);
		QuizName();
	}, [qKeywords]);

	useEffect(() => {
		var myHeaders = new Headers();
		var formdata = new FormData();
		formdata.append("word", qSearch);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "show-suggestions", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setQSuggest(result.data);
				setQList(true);
			})
			.catch((error) => console.log("error", error));
	}, [qSearch]);

	const QuizName = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api + "listofquiz", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setQuizName(result.data);
				setQid(result.data[0].id);
			})
			.catch((error) => console.log("error", error));
	};

	const QuizDetails = async (p_id) => {
		var formdata = new FormData();
		formdata.append("quiz_id", p_id);

		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "listofquestionoptions", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setQuizDetails(result.data);
				setPdfQNo(result.data.length);
			})
			.catch((error) => console.log("error", error));
	};

	const GetPdf = async (p_id) => {
		var formdata = new FormData();
		formdata.append("quiz_id", p_id);
		formdata.append("download", "pdf");
		formdata.append("noOfQuestion", pdfQNo);
		formdata.append("answers", 1);

		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "get-quiz-pdf", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				window.open(result.data, "_blank");
			})
			.catch((error) => console.log("error", error));
	};

	const handlePdfClick = (id) => {
		if (!token) {
			handleShowLogIn();
		} else {
			GetPdf(id);
		}
	};

	const handleQuiz = (id, index) => {
		QuizDetails(id);
		setActiveIndex(index);
	};

	const handleCreatePdf = (e) => {
		e.preventDefault();
		var formdata = new FormData();
		formdata.append("download", "pdf");
		formdata.append("noOfQuestion", 3);
		formdata.append("keywords[]", qKeywords);
		formdata.append("answers", 1);

		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "download-kewords-quiz-id", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				window.open(result.data, "_blank");
				setQNo("");
				setQKeywords([]);
				setAns("");
			})
			.catch((error) => console.log("error", error));
	};

	const handleRadioChange = (event) => {
		console.log(event.target.value);
		setAns(event.target.value);
	};

	const handleSearchKeywords = (e) => {
		setQSearch(e.target.value);
	};

	const handleKeywordSelection = (selectedKeyword) => {
		if (!qKeywords.includes(selectedKeyword)) {
			setQKeywords([...qKeywords, selectedKeyword]);
			console.log(`Selected keyword: ${selectedKeyword}`);
		} else {
			console.log(`Keyword "${selectedKeyword}" is already in the list.`);
		}
		setQList(false);
	};

	return (
		<div>
			<NavigationBar sticky={true} />
			<div className="quiz-details">
				<Row>
					<Col md={3} className="sidebar">
						<Button onClick={handleShow}>{t("create quiz")} </Button>
						{quizName
							? quizName.length > 0
								? quizName.map((n, index) => (
										<div
											key={index}
											className={index === activeIndex ? "active-poem " : ""}
										>
											<h4 onClick={() => handleQuiz(n.id, index)}>
												<span>{index + 1}.</span>
												{n.name}
												<span className="options">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														className="bi bi-filetype-pdf"
														viewBox="0 0 16 16"
														onClick={() => {
															handlePdfClick(n.id);
														}}
													>
														<path
															fill-rule="evenodd"
															d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z"
														/>
													</svg>
												</span>
											</h4>
										</div>
								  ))
								: ""
							: ""}
					</Col>

					<Col md={9} className="main-bar">
						<Row className="quiz">
							{quizDetails
								? quizDetails.length > 0
									? quizDetails.map((n, index) => (
											<div key={index}>
												<strong>{index + 1 + "." + " " + n.q_name}</strong>
												<div className="answers">
													{n.ques_options
														? n.ques_options.length > 0
															? n.ques_options.map((m, ans_index) => (
																	<span key={ans_index}>
																		{ans_index + 1 + "." + " " + m.opt_name}
																	</span>
															  ))
															: ""
														: ""}
												</div>
											</div>
									  ))
									: ""
								: ""}
						</Row>
					</Col>
				</Row>
			</div>
			<Modal
				show={create}
				onHide={handleClose}
				className="create-quiz"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{t("create quiz")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleCreatePdf}>
						{" "}
						<InputGroup>
							<InputGroup.Text className="subject">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="#1a5d1a"
									className="bi bi-search"
									viewBox="0 0 16 16"
								>
									<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
								</svg>
							</InputGroup.Text>
							<Form.Control
								value={qSearch}
								placeholder={t("subject")}
								onChange={(e) => {
									handleSearchKeywords(e);
								}}
							/>
						</InputGroup>
						{qlist && qSuggest && qSuggest.length > 0 && (
							<div className="suggestions">
								<ul>
									{qSuggest.map((n, index) => (
										<li
											key={index}
											onClick={() => handleKeywordSelection(n.keyword_name)}
										>
											{n.keyword_name}
										</li>
									))}
								</ul>
							</div>
						)}
						<div className="quiz-keywords">
							{qKeywords &&
								qKeywords.length > 0 &&
								qKeywords.map((n, index) => (
									<span className="quiz-keyword">{qKeywords[index]}</span>
								))}{" "}
						</div>
						<div className="d-flex justify-content-around mt-5">
							<InputGroup className="mb-3 number">
								<FormControl
									placeholder={t("questions include")}
									aria-describedby="basic-addon2"
									type="number"
									value={q_no}
									onChange={(e) => {
										setQNo(e.target.value);
									}}
								/>
							</InputGroup>

							<div className="my-2 answer">
								{" "}
								<label>{t("answers include")}</label>
								<Form.Check
									inline
									label={t("yes")}
									name="add-answer"
									type="radio"
									value="1"
									checked={q_ans === "1"}
									onChange={handleRadioChange}
								/>
								<Form.Check
									inline
									label={t("no")}
									name="add-answer"
									type="radio"
									value="0"
									checked={q_ans === "0"}
									onChange={handleRadioChange}
								/>
							</div>
						</div>
						<Button className="btn btn-modal" type="submit">
							{t("create quiz")}
						</Button>
					</Form>
				</Modal.Body>
			</Modal>
			<Footer />
			<Login show={showLogin} handleClose={handleCloseLogIn} />
		</div>
	);
}
export default Quiz;
