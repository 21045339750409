import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Modal, Alert, Col, Row } from "react-bootstrap";
import { api } from "../variable";

function Login({ show, handleClose }) {
	const { t } = useTranslation();
	const [userEmail, setUserEmail] = useState("");
	const [userPassword, setUserPassword] = useState("");
	const [results, setResults] = useState("");
	const [error, setError] = useState(false);

	const LogIn = (e) => {
		e.preventDefault();
		var formdata = new FormData();
		formdata.append("email", userEmail);
		formdata.append("password", userPassword);

		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "userlogin", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status === true) {
					localStorage.setItem("token", result.data.token);
					localStorage.setItem("user_id", result.data.user_data.id);
					localStorage.setItem("name", result.data.user_data.name);
					localStorage.setItem("email", result.data.user_data.email);
					localStorage.setItem("phone", result.data.user_data.phone_number);
					localStorage.setItem("user_image", result.data.user_data.user_image);
					localStorage.setItem("authenticate", true);
					localStorage.setItem("access", true);
					localStorage.setItem("entry", false);
					handleClose();
					setError(false);
					setUserEmail("");
					setUserPassword("");
				} else {
					setError(true);
					setResults(t("invalid"));
				}
			})
			.catch((error) => console.log("error", error));
	};

	return (
		<Modal
			show={show}
			onHide={() => {
				handleClose();
				setUserEmail("");
				setUserPassword("");
				setResults("");
				setError("");
			}}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{t("login")}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={LogIn}>
					<Row>
						<Col md={12}>
							<Form.Group className="form-group mb-3">
								<Form.Control
									type="email"
									placeholder="&nbsp;"
									required
									onInvalid={(e) =>
										e.target.setCustomValidity(t("required field"))
									}
									onInput={(e) => e.target.setCustomValidity("")}
									value={userEmail}
									onChange={(e) => setUserEmail(e.target.value)}
								/>{" "}
								<span className="floating-label">{t("email")}</span>
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="form-group mb-3">
								<Form.Control
									type="password"
									placeholder="&nbsp;"
									required
									onInvalid={(e) =>
										e.target.setCustomValidity(t("required field"))
									}
									onInput={(e) => e.target.setCustomValidity("")}
									value={userPassword}
									onChange={(e) => setUserPassword(e.target.value)}
								/>{" "}
								<span className="floating-label">{t("password")}</span>
							</Form.Group>
						</Col>
					</Row>{" "}
					{error && <Alert variant="danger">{results}</Alert>}
					<Button className="btn btn-modal" type="submit">
						{t("login")}
					</Button>
				</Form>
			</Modal.Body>
		</Modal>
	);
}

export default Login;
