import { useEffect, useRef, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { api } from "../variable";
import { useParams } from "react-router-dom";
import Footer from "../Common/Footer";
import NavigationBar from "../Common/Navbar";
import heart from "../Assests/Images/Heart.svg";
import heartFill from "../Assests/Images/Heart_Fill.svg";
import Login from "../Common/Login";

function BookDetails() {
	const { id, poemId } = useParams();
	const { t } = useTranslation();
	const parsedId = parseInt(id, 10);
	const token = localStorage.getItem("token");
	const user_id = localStorage.getItem("user_id");
	const [poemName, setPoemName] = useState("");
	const [poemDetails, setPoemDetails] = useState("");
	const [selectedAudio, setSelectedAudio] = useState("");
	const [selectedVideo, setSelectedVideo] = useState("");
	const [getFvrtBook, setGetFvrtBook] = useState("");
	const [getFvrtPoem, setGetFvrtPoem] = useState("");
	const [activeIndex, setActiveIndex] = useState(null);
	const [fvrtBook, setFvrtBook] = useState(false);
	const [fvrtPoem, setFvrtPoem] = useState({});
	const [showLogin, setShowLogin] = useState(false);
	const [bookName, setBookName] = useState("");
	const [playingIndex, setPlayingIndex] = useState(null);
	const audioRefs = useRef([]);

	const handlePlay = (index) => {
		audioRefs.current.forEach((audioRef, i) => {
			if (audioRef && i !== index) {
				audioRef.pause();
			}
		});

		setPlayingIndex(index);
	};
	const handleCloseLogIn = () => setShowLogin(false);
	const handleShowLogIn = () => setShowLogin(true);

	useEffect(() => {
		Favourites();
		PoemsName();

		if (getFvrtBook && getFvrtBook.length > 0) {
			getFvrtBook.forEach((item, index) => {
				if (item.book_id === parsedId) {
					setFvrtBook(true);
				}
			});
		}

		if (getFvrtPoem && getFvrtPoem.length > 0) {
			const updatedFvrtPoem = {};
			getFvrtPoem.forEach((item) => {
				updatedFvrtPoem[item.poem_id] = true;
			});
			setFvrtPoem(updatedFvrtPoem);
		}
	}, [getFvrtBook, getFvrtPoem]);

	useEffect(() => {
		BookName();
		if (poemId) {
			PoemDetails(poemId);
		}
	}, []);

	const BookName = () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch(api + "listofbooks", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.data && result.data.length > 0) {
					const selected = result.data.find((item) => item.id === parsedId);

					if (selected) {
						setBookName(selected.name);
					}
				}
			})
			.catch((error) => console.log("error", error));
	};

	const Favourites = () => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${token}`);

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(api + "get_fvrts", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setGetFvrtBook(result.favourites.fvrt_book);
				setGetFvrtPoem(result.favourites.fvrt_poems);
			})
			.catch((error) => console.log("error", error));
	};

	const PoemsName = async () => {
		var urlencoded = new URLSearchParams();
		urlencoded.append("book_id", id);
		var requestOptions = {
			method: "POST",
			body: urlencoded,
			redirect: "follow",
		};

		fetch(api + "get_detail_book_poems", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setPoemName(result.data);
			})
			.catch((error) => console.log("error", error));
	};

	const PoemDetails = async (p_id) => {
		var formdata = new FormData();
		formdata.append("poem_id", p_id);

		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "get_poem_detail", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setPoemDetails(result.data.poems);
			})
			.catch((error) => console.log("error", error));
	};

	const GetAudio = async (a_id) => {
		setSelectedAudio("");
		var formdata = new FormData();
		console.log(a_id);
		formdata.append("id", a_id);
		formdata.append("type", "Poem");

		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "search-audio", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setSelectedAudio(result.data);
			})
			.catch((error) => console.log("error", error));
	};

	const GetVideo = async (v_id) => {
		setSelectedVideo("");
		console.log(v_id);
		var formdata = new FormData();
		formdata.append("id", v_id);
		formdata.append("type", "Poem");

		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "search-video", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setSelectedVideo(result.data);
			})
			.catch((error) => console.log("error", error));
	};

	const GetPdf = async (p_id) => {
		var formdata = new FormData();
		formdata.append("poem_id", p_id);

		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "get-poem-pdf", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				window.open(result.data, "_blank");
			})
			.catch((error) => console.log("error", error));
	};

	const FvrtBook = async (fb_id) => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${token}`);
		var urlencoded = new URLSearchParams();
		urlencoded.append("id", fb_id);
		urlencoded.append("user_id", user_id);
		urlencoded.append("fvrt_type", "Book");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: urlencoded,
			redirect: "follow",
		};

		fetch(api + "insertfvrt", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.message === "Added Book to favourites") {
					setFvrtBook(true);
				} else {
					setFvrtBook(false);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const FvrtPoem = async (fp_id) => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${token}`);
		var urlencoded = new URLSearchParams();
		urlencoded.append("id", fp_id);
		urlencoded.append("user_id", user_id);
		urlencoded.append("fvrt_type", "Poem");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: urlencoded,
			redirect: "follow",
		};

		fetch(api + "insertfvrt", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.message === "Added Poem to favourites") {
					setFvrtPoem((prevFvrtPoem) => ({
						...prevFvrtPoem,
						[fp_id]: true,
					}));
				} else {
					setFvrtPoem((prevFvrtPoem) => ({
						...prevFvrtPoem,
						[fp_id]: false,
					}));
				}
			})
			.catch((error) => console.log("error", error));
	};

	const handlePoem = (id, index) => {
		PoemDetails(id);
		setActiveIndex(index);
		GetAudio(id);
		GetVideo(id);
	};

	const handlePdfClick = (id) => {
		if (!token) {
			handleShowLogIn();
		} else {
			GetPdf(id);
		}
	};

	const handleLikeClick = (id) => {
		if (!token) {
			handleShowLogIn();
		} else {
			FvrtPoem(id);
		}
	};

	const handleLikeBook = (id) => {
		if (!token) {
			handleShowLogIn();
		} else {
			FvrtBook(id);
		}
	};

	const convertToEmbedUrl = (watchUrl) => {
		const videoIdMatch = watchUrl.match(
			/(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=))([^"&?\/\s]{11})/
		);

		if (videoIdMatch && videoIdMatch[1]) {
			const videoId = videoIdMatch[1];
			return `https://www.youtube.com/embed/${videoId}`;
		} else {
			return null;
		}
	};

	return (
		<div>
			<NavigationBar sticky={true} />
			<div className="book-details">
				<Row>
					<Col md={3} className="sidebar">
						<h1>
							<img
								src={fvrtBook ? heartFill : heart}
								alt=""
								onClick={() => handleLikeBook(id)}
							/>
							{bookName}
						</h1>

						{poemName
							? poemName.length > 0
								? poemName.map((n, index) => (
										<div
											key={index}
											className={index === activeIndex ? "active-poem " : ""}
										>
											<h3 onClick={() => handlePoem(n.id, index)}>
												<span>
													{index + 1}.{n.poem_name}
												</span>

												<span className="options">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														className="bi bi-filetype-pdf"
														viewBox="0 0 16 16"
														onClick={() => {
															handlePdfClick(n.id);
														}}
													>
														<path
															fill-rule="evenodd"
															d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z"
														/>
													</svg>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														className="bi bi-heart"
														viewBox="0 0 16 16"
														onClick={() => handleLikeClick(n.id)}
													>
														{fvrtPoem[n.id] ? (
															<path
																fill-rule="evenodd"
																d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
															/>
														) : (
															<path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
														)}
													</svg>
												</span>
											</h3>
										</div>
								  ))
								: ""
							: ""}
					</Col>

					<Col md={9} className="main-bar">
						<Row>
							<Col md={6} className="poem">
								<h1>{poemDetails.poem_name}</h1>
								<br />
								<span
									style={{ whiteSpace: "pre-line" }}
									dangerouslySetInnerHTML={{ __html: poemDetails.full_poem }}
								></span>
								<br />
							</Col>

							<Col md={6}>
								<div className="meanings">
									<Tabs
										defaultActiveKey="word"
										className="meaning-tab mb-3"
										fill
									>
										<Tab eventKey="video" title={t("videos")}>
											{selectedVideo
												? selectedVideo.length > 0
													? selectedVideo.map((n, index) => (
															<div key={index} className="video">
																<iframe
																	width="98%"
																	height="300"
																	src={convertToEmbedUrl(n.youtubeurl)}
																	frameborder="0"
																	allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
																	allowfullscreen
																></iframe>
															</div>
													  ))
													: ""
												: ""}
										</Tab>
										<Tab eventKey="audio" title={t("audios")}>
											{selectedAudio
												? selectedAudio.map((audio, index) => (
														<div key={index}>
															<audio
																ref={(audioRef) =>
																	(audioRefs.current[index] = audioRef)
																}
																controls
																controlsList="nodownload"
																onPlay={() => handlePlay(index)}
																onPause={() => setPlayingIndex(null)}
															>
																<source
																	src={audio.youtubeurl}
																	type="audio/mp3"
																/>
															</audio>
														</div>
												  ))
												: ""}
										</Tab>

										<Tab eventKey="word" title={t("word meaning")}>
											<Row className="meaning">
												<ul className="ul-meaning">
													{poemDetails.words_meanings
														? poemDetails.words_meanings.length > 0
															? poemDetails.words_meanings.map((n, index) => (
																	<div key={index}>
																		<li>
																			<Row>
																				<Col md={4}>
																					<span>{n.word}</span>
																				</Col>
																				<Col md={8}>
																					<span>{n.meaning}</span>
																				</Col>
																			</Row>
																		</li>
																	</div>
															  ))
															: ""
														: ""}
												</ul>
											</Row>
										</Tab>
										<Tab eventKey="translation" title={t("translation")}>
											<Row className="translation">
												<span
													style={{ whiteSpace: "pre-line" }}
													dangerouslySetInnerHTML={{
														__html: poemDetails.translation,
													}}
												></span>
											</Row>
										</Tab>
										<Tab eventKey="explanation" title={t("explanation")}>
											<Row className="explanation">
												<span
													style={{ whiteSpace: "pre-line" }}
													dangerouslySetInnerHTML={{
														__html: poemDetails.desc,
													}}
												></span>
											</Row>
										</Tab>
									</Tabs>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
			<Footer />
			<Login show={showLogin} handleClose={handleCloseLogIn} />
		</div>
	);
}
export default BookDetails;
