import { useEffect, useState } from "react";
import {
	Button,
	Col,
	Image,
	Row,
	Tab,
	Tabs,
	Modal,
	Form,
	Alert,
} from "react-bootstrap";
import { api, userImageApi } from "../variable";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Footer from "../Common/Footer";
import NavigationBar from "../Common/Navbar";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function Profile() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const name = localStorage.getItem("name");
	const email = localStorage.getItem("email");
	const phone = localStorage.getItem("phone");
	const token = localStorage.getItem("token");
	const authenticate = localStorage.getItem("authenticate");
	const user_image = localStorage.getItem("user_image");
	const user_id = localStorage.getItem("user_id");
	const [fvrtBook, setFvrtBook] = useState("");
	const [fvrtPoems, setFvrtPoems] = useState("");
	const [fvrtStanza, setFvrtStanza] = useState("");
	const [fvrtWord, setFvrtWord] = useState("");
	const [updateModal, setUpdateModal] = useState(false);
	const [profileModal, setProfileModal] = useState(false);
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [results, setResults] = useState("");
	const [errors, setError] = useState("");
	const [userName, setUserName] = useState(name);
	const [userEmail, setUserEmail] = useState(email);
	const [userPassword, setUserPassword] = useState("");
	const [userConfirmPassword, setUserConfirmPassword] = useState("");
	const [userPhone, setUserPhone] = useState(phone);
	const [userImage, setUserImage] = useState("");

	const handleModalClose = () => {
		setUpdateModal(false);
		setOldPassword("");
		setNewPassword("");
		setConfirmPassword("");
	};
	const handleModalShow = () => setUpdateModal(true);

	const handleProfileClose = () => {
		setProfileModal(false);
		setUserName("");
		setUserEmail("");
		setUserPassword("");
		setUserConfirmPassword("");
		setUserPhone("");
	};
	const handleProfileShow = () => setProfileModal(true);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		console.log("File", event.target.files[0]);
		setUserImage(file);
	};

	useEffect(() => {
		if (!token && !authenticate) {
			navigate("/");
		} else {
			Details();
		}
	}, [token, authenticate, userName]);

	const Details = async () => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${token}`);

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(api + "get_fvrts", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setFvrtBook(result.favourites.fvrt_book);
				setFvrtPoems(result.favourites.fvrt_poems);
				setFvrtStanza(result.favourites.fvrt_stanza);
			})
			.catch((error) => console.log("error", error));
	};

	const ChangePassword = (e) => {
		e.preventDefault();

		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${token}`);

		var formdata = new FormData();
		formdata.append("oldpassword", oldPassword);
		formdata.append("newpassword", newPassword);
		formdata.append("confirmpassword", confirmPassword);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "changepassworduser", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === true) {
					setOldPassword("");
					setNewPassword("");
					setConfirmPassword("");
					setResults(true);
					setError(false);
				} else {
					setError(true);
					setResults(false);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const UpdateProfile = (e) => {
		e.preventDefault();
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${token}`);

		var formdata = new FormData();
		formdata.append("name", userName);
		formdata.append("password", userPassword);
		formdata.append("password_confirmation", userConfirmPassword);
		formdata.append("email", userEmail);
		formdata.append("phone_number", userPhone);
		formdata.append("user_image", userImage);
		formdata.append("user_id", user_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "updateprofile", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result.data[0].name);
				if (result.status === true) {
					localStorage.setItem("name", result.data[0].name);
					localStorage.setItem("email", result.data[0].email);
					localStorage.setItem("phone", result.data[0].phone_number);

					if (result && result.data[0] && result.data.length > 0) {
						const userImage = result.data[0].user_image;
						if (userImage) {
							const fileName = userImage.split("/").pop();
							localStorage.setItem("user_image", fileName);
							console.log(fileName);
						}
					}
					setProfileModal(false);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const handlePoem = (id) => {
		if (fvrtPoems && fvrtPoems.length > 0) {
			const selectedPoem = fvrtPoems.find((poemItem) => {
				return poemItem.poem_detail.id === id;
			});

			if (selectedPoem) {
				var myHeaders = new Headers();
				myHeaders.append("Authorization", `Bearer ${token}`);

				var urlencoded = new URLSearchParams();
				urlencoded.append("poem_id", id);

				var requestOptions = {
					method: "POST",
					headers: myHeaders,
					body: urlencoded,
					redirect: "follow",
				};

				fetch(api + "get_detail_poems_stanza", requestOptions)
					.then((response) => response.json())
					.then((result) => {
						console.log(result.data.poemDetail);
						if (result.data.poemDetail && result.data.poemDetail.length > 0) {
							result.data.poemDetail.forEach((n) => {
								console.log("fgdg", n.book_id);
								navigate(`/bookdetail/${n.book_id}/${selectedPoem.poem_id}`);
							});
						}
					})
					.catch((error) => console.log("error", error));
			}
		}
	};

	return (
		<div>
			<NavigationBar sticky={true} />

			<div className="profile">
				<Row>
					<Col md={3} className="sidebar">
						<div className="profile-img">
							<Image src={userImageApi + user_image} fluid />
						</div>
						<div className="profile-details">
							<h1>{name}</h1>
							<h4>{email}</h4>
							<h4>{phone}</h4>
							<h3 onClick={handleModalShow}>{t("change password")}</h3>
						</div>
						<Button onClick={handleProfileShow}>{t("update profile")}</Button>
					</Col>

					<Col md={9} className="main-bar">
						<Row className="detail">
							<h1>{t("favorite")}</h1>
							<Tabs
								defaultActiveKey="books"
								id="uncontrolled-tab-example"
								className="mb-3"
								fill
							>
								<Tab eventKey="books" title={t("books")}>
									{fvrtBook
										? fvrtBook.length > 0
											? fvrtBook.map((n, index) => (
													<div key={index}>
														<a href={`/bookdetail/${n.book_detail.id}`}>
															{index + 1 + "." + " " + n.book_detail.name}
														</a>
													</div>
											  ))
											: ""
										: ""}
								</Tab>
								<Tab eventKey="poems" title={t("poems")}>
									{fvrtPoems
										? fvrtPoems.length > 0
											? fvrtPoems.map((n, index) => (
													<div
														key={index}
														onClick={() => handlePoem(n.poem_detail.id)}
													>
														<span>
															{index + 1 + "." + " " + n.poem_detail.name}
														</span>
													</div>
											  ))
											: ""
										: ""}
								</Tab>
								<Tab eventKey="stanza" title={t("stanza")}>
									Tab content for Home
								</Tab>
								<Tab eventKey="dictionary" title={t("dictionary")}>
									Tab content for Home
								</Tab>
							</Tabs>
						</Row>
					</Col>
				</Row>
			</div>

			<Footer />

			<Modal show={updateModal} onHide={handleModalClose} centered>
				<Modal.Header closeButton>
					<Modal.Title>{t("change password")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={ChangePassword}>
						<Row>
							<Col md={12}>
								<Form.Group className="form-group mb-3">
									<Form.Control
										type="password"
										placeholder="&nbsp;"
										required
										onInvalid={(e) =>
											e.target.setCustomValidity(t("required field"))
										}
										onInput={(e) => e.target.setCustomValidity("")}
										value={oldPassword}
										onChange={(e) => {
											setOldPassword(e.target.value);
										}}
									/>{" "}
									<span className="floating-label">{t("old password")}</span>
								</Form.Group>
							</Col>
							<Col md={12}>
								<Form.Group className="form-group mb-3">
									<Form.Control
										type="password"
										placeholder="&nbsp;"
										required
										onInvalid={(e) =>
											e.target.setCustomValidity(t("required field"))
										}
										onInput={(e) => e.target.setCustomValidity("")}
										value={newPassword}
										onChange={(e) => setNewPassword(e.target.value)}
									/>{" "}
									<span className="floating-label">{t("new password")}</span>
								</Form.Group>
							</Col>
							<Col md={12}>
								<Form.Group className="form-group mb-3">
									<Form.Control
										type="password"
										placeholder="&nbsp;"
										required
										onInvalid={(e) =>
											e.target.setCustomValidity(t("required field"))
										}
										onInput={(e) => e.target.setCustomValidity("")}
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
									/>{" "}
									<span className="floating-label">
										{t("confirm password")}
									</span>
								</Form.Group>
							</Col>
						</Row>{" "}
						{results && (
							<Alert variant="success">{t("password changed")}</Alert>
						)}
						{errors && <Alert variant="danger">{t("incorrect details")}</Alert>}
						<Button className="btn btn-modal" type="submit">
							{t("change password")}
						</Button>
					</Form>
				</Modal.Body>
			</Modal>

			<Modal show={profileModal} onHide={handleProfileClose} centered>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						{t("update profile")}
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form onSubmit={UpdateProfile}>
						<Row>
							<Col md={6}>
								<Form.Group className="form-group mb-3">
									<Form.Control
										type="text"
										placeholder="&nbsp;"
										onInvalid={(e) =>
											e.target.setCustomValidity(t("required field"))
										}
										onInput={(e) => e.target.setCustomValidity("")}
										autoFocus
										required
										value={userName}
										onChange={(e) => setUserName(e.target.value)}
									/>{" "}
									<span className="floating-label">{t("name")}</span>
								</Form.Group>
							</Col>
							<Col md={6}>
								<div className="form-group mb-3">
									<PhoneInput
										defaultCountry="PK"
										placeholder={t("phone")}
										onInvalid={(e) =>
											e.target.setCustomValidity(t("required field"))
										}
										onInput={(e) => e.target.setCustomValidity("")}
										required
										value={userPhone}
										onChange={setUserPhone}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<Form.Group className="form-group mb-3">
									<Form.Control
										type="email"
										placeholder="&nbsp;"
										required
										onInvalid={(e) =>
											e.target.setCustomValidity(t("required field"))
										}
										onInput={(e) => e.target.setCustomValidity("")}
										value={userEmail}
										onChange={(e) => {
											setUserEmail(e.target.value);
											console.log(e.target.value);
										}}
									/>{" "}
									<span className="floating-label">{t("email")}</span>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<Form.Group className="form-group mb-3">
									<Form.Control
										type="password"
										placeholder="&nbsp;"
										required
										onInvalid={(e) =>
											e.target.setCustomValidity(t("required field"))
										}
										onInput={(e) => e.target.setCustomValidity("")}
										value={userPassword}
										onChange={(e) => setUserPassword(e.target.value)}
									/>{" "}
									<span className="floating-label">{t("password")}</span>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="form-group mb-3">
									<Form.Control
										type="password"
										placeholder="&nbsp;"
										required
										onInvalid={(e) =>
											e.target.setCustomValidity(t("required field"))
										}
										onInput={(e) => e.target.setCustomValidity("")}
										value={userConfirmPassword}
										onChange={(e) => setUserConfirmPassword(e.target.value)}
									/>{" "}
									<span className="floating-label">
										{t("confirm password")}
									</span>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								<Form.Group controlId="formFile" className="mb-3">
									<Form.Label>{t("select picture")}</Form.Label>
								</Form.Group>
							</Col>
							<Col md={8}>
								<Form.Group controlId="formFile" className="mb-3">
									<Form.Control type="file" onChange={handleFileChange} />
								</Form.Group>
							</Col>
						</Row>{" "}
						{results && <Alert variant="danger">{t("already exists")}</Alert>}
						<Button className="btn btn-modal" type="submit">
							{t("update profile")}
						</Button>
					</Form>
				</Modal.Body>
			</Modal>
		</div>
	);
}
export default Profile;
