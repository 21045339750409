import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { api } from "../variable";
import Footer from "../Common/Footer";
import NavigationBar from "../Common/Navbar";

function Biography() {
	const [bioList, setBioList] = useState("");
	const [details, setDetails] = useState("");
	const [activeIndex, setActiveIndex] = useState(null);
	const [bioId, setBioId] = useState("");

	useEffect(() => {
		BioList();
	}, []);

	const BioList = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api + "get_all_biography", requestOptions)
			.then((response) => response.json())
			.then( ( result ) =>
			{
				setBioId(result.data[0].bio_id);
				setBioList(result.data);
			})
			.catch((error) => console.log("error", error));
	};

	const Details = async ( p_id ) =>
	{
		console.log("details", p_id)
		var urlencoded = new URLSearchParams();
		urlencoded.append("bio_id", p_id);

		var requestOptions = {
			method: "POST",
			body: urlencoded,
			redirect: "follow",
		};

		fetch(api + "get_detail_ocassion", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setDetails(result.data);
			})
			.catch((error) => console.log("error", error));
	};

	const handlePoem = (id, index) => {
		Details(id);
		setActiveIndex(index);
	};

	return (
		<div>
			<NavigationBar sticky={true} />
			<div className="bio-details">
				<Row>
					<Col md={3} className="sidebar">
						{bioList
							? bioList.length > 0
								? bioList.map((n, index) => (
										<div
											key={index}
											className={index === activeIndex ? "active-poem " : ""}
										>
											<h3 onClick={() => handlePoem(n.bio_id, index)}>
												<span>{index + 1}.</span>
												{n.bio_name}
											</h3>
										</div>
								  ))
								: ""
							: ""}
					</Col>
					
					<Col md={9} className="main-bar">
						<Row className="detail">
							{details
								? details.length > 0
									? details.map((n, index) => (
											<div key={index}>
												<h1>{n.bio_name}</h1>
												<span
													style={{ whiteSpace: "pre-line" }}
													dangerouslySetInnerHTML={{
														__html: n.desc,
													}}
												></span>
											</div>
									  ))
									: ""
								: ""}
							<br />

							<br />
						</Row>
					</Col>
				</Row>
			</div>
			<Footer />
		</div>
	);
}
export default Biography;
