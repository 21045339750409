import { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { api } from "../variable";
import Footer from "../Common/Footer";
import NavigationBar from "../Common/Navbar";

function Challenge() {
	const [stanza, setStanza] = useState("");

	useEffect(() => {
		Stanzas();
	}, []);

	const Stanzas = (alpha) => {
		var formdata = new FormData();
		formdata.append("character", alpha);

		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "search-by-character", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setStanza(result.data);
			})
			.catch((error) => console.log("error", error));
	};
	const handleTabSelect = (key) => {
		Stanzas(key);
	};
	return (
		<div>
			<NavigationBar />
			<Container className="challenge">
				<div className="challenge-filter">
					<div className="challenge-type">
						<Tabs
							defaultActiveKey="آ"
							className="mb-3"
							onSelect={handleTabSelect}
						>
							<Tab eventKey="آ" title="آ"></Tab>
							<Tab eventKey="ا" title="ا"></Tab>
							<Tab eventKey="ب" title="ب"></Tab>
							<Tab eventKey="پ" title="پ"></Tab>
							<Tab eventKey="ت" title="ت"></Tab>
							<Tab eventKey="ج" title="ج"></Tab>
							<Tab eventKey="چ" title="چ"></Tab>
							<Tab eventKey="ح" title="ح"></Tab>
							<Tab eventKey="خ" title="خ"></Tab>
							<Tab eventKey="د" title="د"></Tab>
							<Tab eventKey="ذ" title="ذ"></Tab>
							<Tab eventKey="ر" title="ر"></Tab>
							<Tab eventKey="ز" title="ز"></Tab>
							<Tab eventKey="س" title="س"></Tab>
							<Tab eventKey="ش" title="ش"></Tab>
							<Tab eventKey="ص" title="ص"></Tab>
							<Tab eventKey="ض" title="ض"></Tab>
							<Tab eventKey="ط" title="ط"></Tab>
							<Tab eventKey="ظ" title="ظ"></Tab>
							<Tab eventKey="ع" title="ع"></Tab>
							<Tab eventKey="غ" title="غ"></Tab>
							<Tab eventKey="ف" title="ف"></Tab>
							<Tab eventKey="ق" title="ق"></Tab>
							<Tab eventKey="ک" title="ک"></Tab>
							<Tab eventKey="ل" title="ل"></Tab>
							<Tab eventKey="م" title="م"></Tab>
							<Tab eventKey="ن" title="ن"></Tab>
							<Tab eventKey="ں" title="ں"></Tab>
							<Tab eventKey="و" title="و"></Tab>
							<Tab eventKey="ہ" title="ہ"></Tab>
							<Tab eventKey="ھ" title="ھ"></Tab>
							<Tab eventKey="ء" title="ء"></Tab>
							<Tab eventKey="ی" title="ی"></Tab>
							<Tab eventKey="ے" title="ے"></Tab>
						</Tabs>
					</div>
				</div>
				<div className="challenge-stanzas">
					{stanza
						? stanza.length > 0
							? stanza.map((n, index) => {
									const htmlContent = n.full_stanza;
									const lines = htmlContent.split("\n");
									const truncatedContent = lines.slice(0, 2).join("\n");

									return (
										<div className="stanza" key={index}>
											<span
												style={{ whiteSpace: "pre-line" }}
												dangerouslySetInnerHTML={{
													__html: truncatedContent,
												}}
											></span>
										</div>
									);
							  })
							: ""
						: ""}{" "}
					
				</div>
			</Container>
			<Footer />
		</div>
	);
}
export default Challenge;
