import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	Container,
	Nav,
	Navbar,
	Image,
	Form,
	InputGroup,
	NavDropdown,
} from "react-bootstrap";
import logo from "../Assests/Images/logo.png";
import Login from "./Login";
import SignUp from "./SignUp";
import { api } from "../variable";

function NavigationBar(props) {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const [qSearch, setQSearch] = useState(props.search);
	const [qSuggest, setQSuggest] = useState("");
	const [qlist, setQList] = useState(false);
	const token = localStorage.getItem("token");
	const authenticate = localStorage.getItem("authenticate");
	const [entry, setEntry] = useState(localStorage.getItem("entry"));
	const [access, setAccess] = useState(localStorage.getItem("access"));
	const currentUrl = location.pathname;
	const [showSignUp, setShowSignUp] = useState(false);
	const [showLogin, setShowLogin] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);

	const handleCloseSignUp = () => setShowSignUp(false);
	const handleShowSignUp = () => setShowSignUp(true);

	const handleCloseLogIn = () => setShowLogin(false);
	const handleShowLogIn = () => setShowLogin(true);

	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("authenticate");
		localStorage.removeItem("name");
		localStorage.removeItem("email");
		localStorage.removeItem("phone");
		localStorage.removeItem("user_id");
		localStorage.removeItem("user_image");
		navigate("/");
	};

	useEffect(() => {
		if (!token && !authenticate) {
			setAccess(false);
			setEntry(true);
		} else {
			setAccess(true);
			setEntry(false);
		}
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [token, authenticate]);

	const handleScroll = () => {
		const scrollPosition = window.scrollY;
		const scrollThreshold = 10;
		setIsScrolled(scrollPosition > scrollThreshold);
	};

	useEffect(() => {
		var myHeaders = new Headers();
		var formdata = new FormData();
		formdata.append("word", qSearch);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api + "show-suggestions", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setQSuggest(result.data);
				setQList(true);
			})
			.catch((error) => console.log("error", error));
	}, [qSearch]);

	const handleSearchKeywords = (e) => {
		setQSearch(e.target.value);
		navigate(`/search/${e.target.value}`);
	};

	const handleKeywordSelection = (selectedKeyword) => {
		navigate(`/search/${selectedKeyword}`);
		setQSearch(selectedKeyword);
		setQList(false);
	};

	return (
		<div>
			<Navbar
				expand="lg"
				fixed="top"
				className={isScrolled || props.sticky ? "scrolled" : ""}
			>
				<Container>
					<Navbar.Brand href="/">
						<Image src={logo} rounded fluid />
					</Navbar.Brand>

					<Navbar.Toggle aria-controls="basic-navbar-nav" />

					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="mx-auto">
							<Nav.Link
								href="/"
								className={currentUrl === "/" ? "active-link" : ""}
							>
								{t("home")}
							</Nav.Link>
							<Nav.Link
								href="/about"
								className={currentUrl === "/about" ? "active-link" : ""}
							>
								{t("about")}
							</Nav.Link>
							<Nav.Link
								href="/books"
								className={currentUrl === "/books" ? "active-link" : ""}
							>
								{t("books")}
							</Nav.Link>
							<Nav.Link
								href="/videos"
								className={currentUrl === "/videos" ? "active-link" : ""}
							>
								{t("videos")}
							</Nav.Link>
							<Nav.Link
								href="/audios"
								className={currentUrl === "/audios" ? "active-link" : ""}
							>
								{t("audios")}
							</Nav.Link>
						</Nav>
						<Nav className="search mx-auto">
							<InputGroup>
								<InputGroup.Text id="basic-addon1">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="black"
										class="bi bi-search"
										viewBox="0 0 16 16"
									>
										<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
									</svg>
								</InputGroup.Text>
								<Form.Control
									placeholder={t("search")}
									value={qSearch}
									onChange={(e) => {
										handleSearchKeywords(e);
									}}
								/>
							</InputGroup>
							{qlist && qSuggest && qSuggest.length > 0 && (
								<div className="suggestions">
									<ul>
										{qSuggest.map((n, index) => (
											<li
												key={index}
												onClick={() => handleKeywordSelection(n.keyword_name)}
											>
												{n.keyword_name}
											</li>
										))}
									</ul>
								</div>
							)}
						</Nav>

						<Nav className="mx-auto">
							<Nav.Link
								href="/kids"
								className={currentUrl === "/kids" ? "active-link" : ""}
							>
								{t("kids")}
							</Nav.Link>
							<Nav.Link
								href="/biography"
								className={currentUrl === "/biography" ? "active-link" : ""}
							>
								{t("biography")}
							</Nav.Link>
							<Nav.Link
								href="/gallery"
								className={currentUrl === "/gallery" ? "active-link" : ""}
							>
								{t("photo")}
							</Nav.Link>
							{entry && (
								<>
									<Nav.Link onClick={handleShowLogIn}>{t("login")}</Nav.Link>
									<Nav.Link onClick={handleShowSignUp} className="sign-up">
										{t("sign")}
									</Nav.Link>
								</>
							)}
						</Nav>

						{access && (
							<NavDropdown
								title={
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="34"
										height="34"
										fill="black"
										className="bi bi-person-circle"
										viewBox="0 0 16 16"
									>
										<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
										<path
											fill-rule="evenodd"
											d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
										/>
									</svg>
								}
								id="user-account"
							>
								<NavDropdown.Item href="/profile">
									{t("profile")}
								</NavDropdown.Item>
								<NavDropdown.Item onClick={handleLogout}>
									{t("logout")}
								</NavDropdown.Item>
							</NavDropdown>
						)}
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<Login show={showLogin} handleClose={handleCloseLogIn} />
			<SignUp show={showSignUp} handleClose={handleCloseSignUp} />
		</div>
	);
}
export default NavigationBar;
