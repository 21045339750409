import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Image } from "react-bootstrap";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { api } from "../variable";
import Footer from "../Common/Footer";
import NavigationBar from "../Common/Navbar";
import img from "../Assests/Images/gallery.jpg";

function Gallery() {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [images, setImages] = useState([]);
	useEffect(() => {
		getAllData();
	}, []);

	const getAllData = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api + "get_home", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setImages(result.data.banners);
			})
			.catch((error) => console.log("error", error));
	};

	const openModal = (index) => {
		setCurrentIndex(index);
		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
	};

	const goToPrevious = () => {
		setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
	};

	const goToNext = () => {
		setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
	};

	return (
		<div>
			<NavigationBar />
			<div className="gallery-header">
				<div className="image-overlay">
					<Image src={img} fluid />
				</div>
				<div className="text-outline">
					<h1>{t("photo")}</h1>
				</div>
			</div>
			<Container className="gallery">
				{images.map((n, index) => (
					<div
						key={index}
						className="gallery-img"
						onClick={() => openModal(index)}
					>
						<Image src={n.banner_image} fluid />
					</div>
				))}
			</Container>
			<Footer />
			<Modal open={open} onClose={closeModal} center>
				<div className="modal-content">
					{images.length > 0 && (
						<Image src={images[currentIndex].banner_image} fluid />
					)}
					<button className="prev-btn" onClick={goToPrevious}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							class="bi bi-arrow-left"
							viewBox="0 0 16 16"
						>
							<path
								fill-rule="evenodd"
								d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
							/>
						</svg>
					</button>
					<button className="next-btn" onClick={goToNext}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							class="bi bi-arrow-right"
							viewBox="0 0 16 16"
						>
							<path
								fill-rule="evenodd"
								d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
							/>
						</svg>
					</button>
				</div>
			</Modal>
		</div>
	);
}
export default Gallery;
