import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { api } from "../variable";
import Footer from "../Common/Footer";
import NavigationBar from "../Common/Navbar";

function About() {
	const { t } = useTranslation();
	const [about, setAbout] = useState("");

	useEffect(() => {
		About();
	}, [] );
	
	const About = () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api + "get-about-content", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setAbout(result.data);
			})
			.catch((error) => console.log("error", error));
	};

	return (
		<div>
			<NavigationBar />
			<Container className="about">
				<h1>{t("about")}</h1>

				{about
					? about.length > 0
						? about.map((n, index) => (
								<div key={index}>
									<p
										style={{ whiteSpace: "pre-line" }}
										dangerouslySetInnerHTML={{ __html: n.opt_value }}
									></p>
								</div>
						  ))
						: ""
					: "" }
				
			</Container>
			<Footer />
		</div>
	);
}
export default About;
