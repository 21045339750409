import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Container, Image } from "react-bootstrap";
import Footer from "../Common/Footer";
import NavigationBar from "../Common/Navbar";
import background from "../Assests/Images/Background.svg";
import books from "../Assests/Images/Books.svg";
import bottom from "../Assests/Images/Bottom.svg";
import bulb from "../Assests/Images/Bulb.svg";
import glass from "../Assests/Images/Glass.svg";
import largeBook from "../Assests/Images/Large_Book.svg";
import leaf from "../Assests/Images/Left_Leaf.svg";
import outline from "../Assests/Images/Outline_Book.svg";
import person1 from "../Assests/Images/Person1.svg";
import person2 from "../Assests/Images/Person2.svg";
import smallBook from "../Assests/Images/Small_Book.svg";
import "animate.css";

function Kids() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handlePoems = () => {
		navigate("/kids/poems");
	};
	const handleQuiz = () => {
		navigate("/kids/quiz");
	};
	const handleChallenge = () => {
		navigate("/kids/challenge");
	};
	return (
		<div>
			<NavigationBar />
			<Container className="kids">
				<div className="kids-header">
					<div className="right">
						<h1>{t("kids selection")}</h1>
						<div className="cards animate__animated animate__slow animate__fadeIn">
							<div className="card" onClick={handlePoems}>
								<h2>{t("poems")}</h2>
							</div>
							<div
								className="card animate__animated animate__slow animate__fadeIn"
								onClick={handleQuiz}
							>
								<h2>{t("quiz")}</h2>
							</div>
							<div
								className="card animate__animated animate__slow animate__fadeIn"
								onClick={handleChallenge}
							>
								<h2>{t("game")}</h2>
							</div>
						</div>
					</div>

					<div className="left">
						<div className="img">
							{" "}
							<Image
								src={leaf}
								className="leaf animate__animated animate__slow animate__backInUp"
								fluid
							/>
							<Image
								src={background}
								className="background animate__animated animate__slow animate__backInUp"
								fluid
							/>
							<Image
								src={bottom}
								className="bottom animate__animated animate__slow animate__bounceIn"
								fluid
							/>
							<Image
								src={person1}
								className="person1 animate__animated animate__slow animate__fadeInLeft"
								fluid
							/>
							<Image
								src={glass}
								className="glass animate__animated animate__slow animate__rotateInDownRight"
								fluid
							/>
							<Image
								src={bulb}
								className="bulb animate__animated animate__slow animate__fadeInRight"
								fluid
							/>
							<Image
								src={largeBook}
								className="largeBook animate__animated animate__slow animate__zoomIn"
								fluid
							/>
							<Image
								src={books}
								className="books1 animate__animated animate__slow animate__bounceIn"
								fluid
							/>
							<Image
								src={person2}
								className="person2 animate__animated animate__slow animate__fadeInRight"
								fluid
							/>
							<Image
								src={outline}
								className="outline animate__animated animate__slow animate__rotateInDownLeft"
								fluid
							/>
							<Image
								src={smallBook}
								className="smallBook animate__animated animate__slow animate__rotateInDownLeft"
								fluid
							/>
						</div>{" "}
					</div>
				</div>
			</Container>
			<Footer />
		</div>
	);
}

export default Kids;
